.main-alert {
  position: absolute;
  bottom: 8%;
  left: 2%;
  max-width: 24%;
  width: 100%;
  z-index: 999999;
  [data-alert] {
    animation: show 0.3s forwards;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    min-height: 60px;
    word-break: break-all;
    font-size: 13px;
    padding: 0.5rem 3rem 0.5rem 0.5rem;
    border-radius: 0.5rem;
    border: 1px solid $theme-transparent;
    box-shadow: rgba($theme-black, 0.24) 0px 3px 8px;
    Button {
      right: 3px;
      top: 3px;
    }
  }
}

@keyframes show {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
