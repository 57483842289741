// [data-tooltip]::before {
//   position: absolute;
//   // bottom: 100%;
//   // width: max-content;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   box-sizing: border-box;
//   content: attr(data-tooltip);
//   transform: translateX(-100%) translateY(-100%);
//   color: #ffffff;
//   background: #3d246c;
//   border-radius: 8px;
//   padding: 8px 8px;
//   max-width: 270px;

//   visibility: hidden;
//   opacity: 1;
//   transform: translateY(10px);
//   transition: opacity 0.3s, transform 0.2s;
// }
// [data-tooltip]:hover::before {
//   opacity: 1;
//   visibility: visible;
//   transform: translateY(0);
// }

// .tool {
//   position: relative;
//   text-align: center;
//   &::after,
//   &::before {
//     left: 50%;
//     opacity: 1;
//     position: absolute;
//     z-index: -10;
//   }
//   &:hover,
//   &:focus {
//     &::after,
//     &::before {
//       opacity: 1;
//       transform: scale(1) translateY(0);
//       z-index: 100;
//     }
//   }
//   &::before {
//     border-style: solid;
//     border-width: 1em 0.75em 0 0.75em;
//     border-color: #333 transparent transparent transparent;
//     bottom: 100%;
//     content: '';
//     margin-left: -0.2em;
//     transition: all 0.65s cubic-bezier(0.84, -0.18, 0.31, 1, 0.26),
//       opacity 0.65s 0.5s;
//     transform: scale(0.6) translateY(-90%);
//   }
//   &:hover,
//   &:focus {
//     &::before {
//       transition: all 0.65s cubic-bezier(0.84, -0.18, 0.31, 1, 0.26) 0.5s;
//     }
//   }
//   &::after {
//     background: #333;
//     border-radius: 0.25em;
//     bottom: 130%;
//     color: #edeff0;
//     content: attr(data-tip);
//     margin-left: -6.3em;
//     padding: 0.5em;
//     transition: all 0.65s cubic-bezier(0.84, -0.18, 0.31, 1, 0.26) 0.5s;
//     transform: scale(0.6) translateY(50%);
//     border-radius: 5px;
//     min-width: 150px;
//     max-width: 150px;
//   }
//   &:hover,
//   &:focus {
//     &::after {
//       transition: all 0.65s;
//     }
//   }
// }
/***************Tooltip **************/
[data-tooltip][tooltip-position] {
  position: relative;
  letter-spacing: 0.1rem;

  &:before,
  &:after {
    --scale: 0;
    position: absolute;
    font-size: 1rem;
    transition: transform 100ms;
    transition-timing-function: linear;
  }
  &:hover {
    &::before,
    &:after {
      --scale: 1;
      transition-timing-function: cubic-bezier(0.25, 0.1, 0.45, 1.93);
    }
  }
  &:before {
    content: attr(data-tooltip);
    background-color: $theme-black;
    padding: 0.5em;
    border-radius: 0.3em;
    width: max-content;
    max-width: 200px;
    text-align: center;
    transform: scale(0);
  }
  &:after {
    content: '';
    border: var(--arrow-size) solid transparent;
  }
  &:after,
  &::before {
    // --tooltip-color: orange;
    --arrow-size: 0.5rem;
    --scale: 0;

    z-index: 1;
    font-size: 0.8rem;
    color: $theme-white;
    transform: translate(var(--translate-x), var(--translate-y))
      scale(var(--scale));
  }
}

// [data-tooltip][tooltip-position]::before,
// [data-tooltip][tooltip-position]::after {
//   // --tooltip-color: orange;
//   --arrow-size: 0.5rem;
//   --scale: 0;

//   z-index: 1;
//   font-size: 1rem;
//   color: $theme-white;
//   transform: translate(var(--translate-x), var(--translate-y))
//     scale(var(--scale));
// }

/** Right  */
[data-tooltip][tooltip-position='right'] {
  &::before,
  &::after {
    --translate-y: -50%;
    right: 0px;
    top: 50%;
    transform-origin: left center;
  }
  &:before {
    --translate-x: calc(100% + var(--arrow-size));
  }
  &:after {
    --translate-x: calc(var(--arrow-size));
    border-right-color: $theme-primary;
  }
}

/** Bottom  */
[data-tooltip][tooltip-position='bottom'] {
  &:before,
  &:after {
    bottom: 0px;
    left: 50%;
    --translate-x: -50%;
    transform-origin: top center;
  }
  &:before {
    --translate-y: calc(100% + var(--arrow-size));
  }
  &:after {
    --translate-y: calc(var(--arrow-size));

    border-bottom-color: $theme-primary;
  }
}

/** Top */
[data-tooltip][tooltip-position='top'] {
  &:before,
  &::after {
    --translate-x: -50%;
    top: 0px;
    left: 50%;
    transform-origin: bottom center;
  }
  &:before {
    position: absolute;
    --translate-y: calc(-100% - var(--arrow-size));
    
  }
  &::after {
    --translate-y: calc(-1 * var(--arrow-size));
    border-top-color: $theme-black;
  }
}

/** Left */
[data-tooltip][tooltip-position='left'] {
  &::before,
  &::after {
    --translate-y: -50%;
    left: 0px;
    top: 50%;
    transform-origin: right center;
  }
  &:before {
    --translate-x: calc(-100% - var(--arrow-size));
  }
  &::after {
    --translate-x: calc(-1 * var(--arrow-size));

    border-left-color: $theme-primary;
  }
}

/** Top */

[data-tooltip][tooltip-position='top'] {
  &:before,
  &:after {
    --translate-x: -50%;
    top: 0px;
    left: 50%;
    transform-origin: bottom center;
  }
  &:before {
    position: absolute;
    --translate-y: calc(-100% - var(--arrow-size));
  }
  &:after {
    --translate-y: calc(-1 * var(--arrow-size));
    border-top-color: $theme-black;
  }
}

/** Left */

[data-tooltip][tooltip-position='left'] {
  &::after,
  &::before {
    --translate-y: -50%;
    left: 0px;
    top: 50%;
    transform-origin: right center;
  }
  &::before {
    --translate-x: calc(-100% - var(--arrow-size));
    // --translate-y: -50%;

    // left: 0px;
    // top: 50%;
    // transform-origin: right center;
  }
  &::after {
    --translate-x: calc(-1 * var(--arrow-size));
    // --translate-y: -50%;
    // left: 0px;
    // top: 50%;
    // transform-origin: right center;
    border-left-color: $theme-primary;
  }
}


/* Success */
[data-tooltip][tooltip-position='success'] {
  &:before,
  &::after {
    --translate-x: -50%;
    top: 0px;
    left: 50%;
    transform-origin: bottom center;
  }
  &:before {
    position: absolute;
    --translate-y: calc(-100% - var(--arrow-size));
    background-color: $theme-success;
  }
  &::after {
    --translate-y: calc(-1 * var(--arrow-size));
    border-top-color: $theme-success;
  }
}

/*failure*/

[data-tooltip][tooltip-position='failure'] {
  &:before,
  &::after {
    --translate-x: -50%;
    top: 0px;
    left: 50%;
    transform-origin: bottom center;
  }
  &:before {
    position: absolute;
    --translate-y: calc(-100% - var(--arrow-size));
    background-color: $theme-primary;
  }
  &::after {
    --translate-y: calc(-1 * var(--arrow-size));
    border-top-color: $theme-primary;
  }
}
