@mixin borderClassGenerator($border-arr, $clr-arr) {
  @each $key, $value in $border-arr {
    @each $clr-key, $clr-value in $clr-arr {
      .border-#{$key}--#{$clr-key} {
        @if $key == 'full' {
          border: #{$value} #{$clr-value};
        } @else {
          border-#{$key}: #{$value} #{$clr-value};
        }
      }
    }
  }
}
@mixin radiusClassGenerator($property, $accessor, $arr, $unit) {
  @each $key, $value in $arr {
    @each $pos-key, $pos-value in $positions {
      @if $pos-key == 't' or $pos-key == 'b' {
        #{$accessor}-#{$pos-key}-left--#{$key} {
          #{$property}-#{$pos-value}-left-radius: #{$value}#{$unit};
        }
        #{$accessor}-#{$pos-key}-right--#{$key} {
          #{$property}-#{$pos-value}-right-radius: #{$value}#{$unit};
        }
      }
    }
  }
}
