.userDetails {
  .userDetailsCommon {
    .table--responsive--full-scroll {
      max-height: calc(100vh - 102px);
      min-height: calc(100vh - 102px);
    }
  }
  .planDetailPage {
    max-height: calc(100vh - 101px);
  }
  .profilePage {
    .profilePageSec {
      max-height: calc(100vh - 68px);
    }
  }
}
