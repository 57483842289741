.loginPage {
  z-index: 1;
  &::after {
    content: "";
    position: absolute;
    width: 100%;
    max-width: 50%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 0;
    // background: url("../../assets/images/Loginbg.svg") no-repeat left top;
    background-size: cover;
  }
  &::before {
    content: "";
    position: absolute;
    width: 200px;
    max-width: 258px;
    height: 100%;
    max-height: 385px;
    top: 30%;
    right: 0;
    z-index: 0;
    // background: url("../../assets/images/logincircle.svg") no-repeat left top;
    background-size: contain;
  }
}
