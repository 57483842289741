.form--control {
  &::placeholder {
    font-size: 12px;
    color: $theme-grey;
    font-weight: 400;
  }

  &:has(.PhoneInputInput) {
    padding: 0;
  }
  &.PhoneInput {
    &--focus {
      border-color: $theme-primary;
      box-shadow: 0 0 5px rgba($theme-primary, 0.3);
    }

    .PhoneInputInput {
      border: none;
      min-height: 36px;
      height: 100%;
      padding: 0.5rem 1rem;
      border-radius: 8px;
      &:focus {
        outline: none;
        box-shadow: none;
      }
    }
  }
  &[type='radio'],
  &[type='checkbox'] {
    min-height: 15px;
    min-width: 15px;
  }

  &[type='file']::-webkit-file-upload-button {
    background: $theme-black-100;
    border: none;
    color: $theme-black;
    cursor: pointer;
    border-radius: 5px 0 0 5px;
    color: $theme-grey;
    height: 28px;
    margin-top: 3px;
    margin-left: -8px;
  }
  &.no--focus {
    border: none;
    box-shadow: none;
  }
  &:disabled {
    cursor: not-allowed;
  }
  &.link {
    &::placeholder {
      color: $theme-primary;
    }
  }
}

input,
select,
textarea {
  accent-color: $theme-primary;

  &::placeholder,
  &:required:invalid {
    font-size: 12px;
    color: $theme-black;
    font-weight: 400;
  }

  &:focus {
    outline: none;
    border-color: $theme-primary;
    box-shadow: 0 0 5px rgba($theme-primary, 0.3);
  }
  &[type='radio'] {
    &:focus {
      box-shadow: none;
    }
  }
}
