::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: $theme-contrast;
}

::-webkit-scrollbar {
  width: 6px;
  background-color: $theme-contrast;
}

::-webkit-scrollbar-thumb {
  background-color: $theme-primary;
  border: 1px solid $theme-contrast;
}

.box-shadow {
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
}
.flex--column {
  flex-direction: column;
}
.white--space-none {
  white-space: nowrap;
}
.m--auto {
  margin: auto;
}
.border--0 {
  border: none !important;
}
.inputIcon {
  bottom: -10px;
  right: 10px;
  &.top {
    &--9 {
      top: 9px;
    }
  }
  &.right {
    &--10 {
      right: 10px;
    }
  }
  // bottom: -10px;
  // right: 10px;
}

.right {
  &--10 {
    right: 10px;
  }
}
.top {
  &---5 {
    top: -5px;
  }
  &--100 {
    top: 100%;
  }
  &--15 {
    top: 15px;
  }
  &--22 {
    top: 22px;
  }
}
.left {
  &---8 {
    left: -8px;
  }
}
.bottom {
  &--100 {
    bottom: 100%;
  }
  &--7 {
    bottom: 7px;
  }
}
.p--0 {
  padding: 0 !important;
}
.grid {
  &--2 {
    grid-template-columns: repeat(2, 1fr);
  }
  &--3 {
    grid-template-columns: repeat(3, 1fr);
    @media (min-width: 1800px) {
      &--xl {
        grid-template-columns: repeat(5, 1fr);
      }
    }
    @media (max-width: 767px) {
      &--xs {
        grid-template-columns: repeat(1, 1fr);
      }
    }
  }
  &--4 {
    grid-template-columns: repeat(4, 1fr);
    @media (max-width: 767px) {
      &--xs {
        grid-template-columns: repeat(1, 1fr);
      }
    }
  }
  &--6 {
    grid-template-columns: repeat(6, 1fr);
  }
}
.canvasjs-chart-credit {
  display: none;
}
