*,
html,
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}

html,
body {
  height: 100%;
  font-size: 12px;
}
#root {
  width: 100%;
  height: 100%;
}

img {
  max-width: 100%;
}
a {
  text-decoration: none;
}

.form--control {
  &:has(.PhoneInputInput) {
    padding: 0;
  }
  &.PhoneInput {
    &--focus {
      border-color: $theme-primary;
      box-shadow: 0 0 5px rgba($theme-primary, 0.3);
    }

    .PhoneInputInput {
      border: none;
      min-height: 36px;
      height: 100%;
      padding: 0.5rem 1rem;
      border-radius: 8px;
      &:focus {
        outline: none;
        box-shadow: none;
      }
    }
  }
}
input,
select {
  accent-color: $theme-primary;
  &:focus {
    outline: none;
    border-color: $theme-primary;
    box-shadow: 0 0 5px rgba($theme-primary, 0.3);
  }
  &[type='radio'] {
    &:focus {
      box-shadow: none;
    }
  }
}
button {
  border: none;
  box-shadow: none;
  outline: none;
  inset: unset;
}

[data-link-hover]:hover {
  background-color: $theme-primary-200;
  transition: background-color 0.4s ease-out;
}
[data-link-hover-black-100]:hover {
  background-color: $theme-black-100;
  color: $theme-black;
  transition: background-color 0.4s ease-out;
}
[data-link-hover-white]:hover {
  background-color: $theme-white;
  transition: background-color 0.4s ease-out;
}
