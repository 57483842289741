[dropdown-element] {
  padding: 0.5rem;
  display: flex;
  position: relative;
  cursor: pointer;
}
[dropdown-caret] {
  display: flex;
  align-items: center;
  color: $theme-primary;
}

[dropdown-menu] {
  display: flex;
  flex-direction: column;
  min-width: 150px;
  width: 100%;
  // max-width: 250px;
  padding: 0.5rem;
  border-radius: 0.5rem;
  position: absolute;
  right: 0;
  // top: 100%;
  background-color: $theme-white;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);
  z-index: 2;
  gap: 0.25rem;
}

[dropdown-menu-item] {
  display: flex;
  width: 100%;
  // padding: 0.5rem;
  border-radius: 0.5rem;
  color: $theme-black-600;
  font-weight: 500;
  cursor: pointer;
  &:hover {
    background-color: $theme-primary-200;
    color: $theme-black;
  }
}
